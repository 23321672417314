import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"
import FeaturesHero from "../Hero/FeaturesHero"
import DownloadAppSection from "../DownloadAppSection"
import { useFeaturesPageData } from "../../system/DatoCms/FeaturesPageData"

function Test() {
  const { hero, feature } = useFeaturesPageData()

  return (
    <>
      <FeaturesHero
        title={hero[0].title}
        heroPhones={hero[0].image}
        description={hero[0].description}
      />
      <div className="relative">
        <div className="z-10 mb-10 bg-white/50 backdrop-blur-md md:mt-64">
          <LayoutContainer className="font-spartan">
            {React.Children.toArray(
              feature.map((f, index) => (
                <Feature
                  title={f.title}
                  description={f.description}
                  image={f.image}
                  index={index}
                />
              ))
            )}
          </LayoutContainer>
        </div>
        <LayoutContainer>
          <DownloadAppSection />
        </LayoutContainer>
        <div className="pointer-events-none absolute top-[-148px] left-0 -z-10 hidden md:block">
          <StaticImage
            src="https://www.datocms-assets.com/54391/1643957024-corner-circle-2.png"
            alt="Corner Cicle"
            placeholder="blurred"
          />
        </div>
        <div className="pointer-events-none absolute top-[700px] right-0 -z-10 hidden md:block">
          <StaticImage
            src="https://www.datocms-assets.com/54391/1643956763-corner-circle-1.png"
            alt="Corner Circle"
            placeholder="blurred"
          />
        </div>
        <div className="pointer-events-none absolute top-[1450px] left-0 -z-10 hidden rotate-180 scale-x-[-1] transform md:block">
          <StaticImage
            src="https://www.datocms-assets.com/54391/1643957024-corner-circle-2.png"
            alt="Corner Cicle"
            placeholder="blurred"
          />
        </div>
      </div>
    </>
  )
}

export default Test

function Feature({ title, description, image, index }) {
  const r = index % 2 === 0
  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-center md:pt-10">
      <div
        className={`order-2 ${
          r ? "md:order-1" : "md:order-2"
        } grid place-items-center`}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt}
          className="max-w-[360px]"
        />
      </div>
      <div
        className={`order-1 ${
          r ? "md:order-2 md:ml-[313px]" : "md:order-1 md:mr-[313px]"
        } max-w-[460px] md:w-1/2`}
      >
        <h2 className="mb-[10px] mt-[48px] font-spartan text-[24px] font-bold leading-[39px] text-gray-1 md:mb-[20px] md:mt-0 md:text-[64px] md:leading-[76px] md:tracking-[3px]">
          {title}
        </h2>
        <div className="mb-[48px] md:mb-0">
          <StructuredText
            data={description}
            customRules={[
              renderRule(isParagraph, ({ children, key }) => {
                if (children[0].length === 0) return <br />
                return (
                  <p key={key} className="leading-[25.92px]">
                    {children}
                  </p>
                )
              }),
            ]}
          />
        </div>
      </div>
    </div>
  )
}
