import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import Features from "../components/FeaturesPage"
import { Main } from "../components/UI"

const FeaturesPage = () => {
  const canonicalUrl = `https://thetoucan.app/features/`
  return (
    <Layout isHomePage>
      <Seo
        title="Toucan Giving - Monthly donations to your favourite charities"
        canonicalUrl={canonicalUrl}
      />
      <Main>
        <Features />
      </Main>
    </Layout>
  )
}

export default FeaturesPage
