// import React from "react"
// import { motion } from "framer-motion"
// import { InView } from "react-intersection-observer"
// import { useRecoilState } from "recoil"
//
// import { useDownloadAppData } from "../../system/DatoCms/HomepageData"
// import SectionTitle from "../SectionTitle"
// import { Container } from "../UI"
// import { signupModalState } from "../../state"
//
// const DownloadAppSection = () => {
//   const [signUpModal, setSignUpModal] = useRecoilState(signupModalState)
//   const {
//     data: { downloadAppSectionTitle, downloadApp },
//     assets: { iOSDownloadBtn, androidDownloadBtn },
//   } = useDownloadAppData()
//   const { iosDownloadLink, androidDownloadLink } = downloadApp[0]
//
//   const container = {
//     hidden: { opacity: 0, y: 90 },
//     show: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         type: "spring",
//         duration: 1.5,
//         delay: 0.75,
//         staggerChildren: 1,
//       },
//     },
//   }
//
//   const item = {
//     hidden: { opacity: 0, y: 20 },
//     show: { opacity: 1, y: 0 },
//   }
//   return (
//     <InView threshold={0.7} triggerOnce>
//       {({ inView, ref }) => (
//         <section
//           className="mt-[128px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]"
//           ref={ref}
//           itemScope
//         >
//           <Container>
//             <SectionTitle>{downloadAppSectionTitle}</SectionTitle>
//             <div className="hidden md:block">
//               <motion.div
//                 className="flex flex-col items-center justify-center md:flex-row"
//                 variants={container}
//                 initial="hidden"
//                 animate={inView ? "show" : "hidden"}
//               >
//                 <motion.button
//                   className="mb-2 inline-block md:mb-0 md:mr-6"
//                   variants={item}
//                   itemType="button"
//                   onClick={() => setSignUpModal(!signUpModal)}
//                 >
//                   <img
//                     src={iOSDownloadBtn.url}
//                     alt="Download iOS App from Apple Store"
//                     itemProp="image"
//                   />
//                 </motion.button>
//
//                 <motion.button
//                   variants={item}
//                   itemType="button"
//                   onClick={() => setSignUpModal(!signUpModal)}
//                 >
//                   <img
//                     src={androidDownloadBtn.url}
//                     alt="Download Android App from Google Play Store"
//                     itemProp="image"
//                   />
//                 </motion.button>
//               </motion.div>
//             </div>
//             <div className="block md:hidden">
//               <motion.div
//                 className="flex flex-col items-center justify-center md:flex-row"
//                 variants={container}
//                 initial="hidden"
//                 animate={inView ? "show" : "hidden"}
//               >
//                 <motion.a
//                   className="mb-2 inline-block md:mb-0 md:mr-6"
//                   variants={item}
//                   itemProp="url"
//                   href="https://app-toucan-donate.onelink.me/I5ZQ/e13e65e"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <img
//                     src={iOSDownloadBtn.url}
//                     alt="Download iOS App from Apple Store"
//                     itemProp="image"
//                   />
//                 </motion.a>
//
//                 <motion.a
//                   variants={item}
//                   itemProp="url"
//                   href="https://app-toucan-donate.onelink.me/I5ZQ/e13e65e"
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <img
//                     src={androidDownloadBtn.url}
//                     alt="Download Android App from Google Play Store"
//                     itemProp="image"
//                   />
//                 </motion.a>
//               </motion.div>
//             </div>
//           </Container>
//         </section>
//       )}
//     </InView>
//   )
// }
//
// export default DownloadAppSection

const DownloadAppSection = () => {
  return null
}

export default DownloadAppSection