import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const FeaturesHero = ({ title, heroPhones, description }) => {
  return (
    <>
      <section className="relative mt-[-80px] mb-[128px] rounded-b-[50px] bg-hero-mobile pt-[170px] font-spartan md:mt-[-96px] md:mb-[140px] md:rounded-b-[96px] md:pt-[210px] lg:mb-[151px]">
        <LayoutContainer className="relative z-10">
          <div className="max-w-[600px] lg:ml-28 xl:w-[700px]">
            <h2
              className={`custom-text-shadow mb-[38px] pb-[150px] text-center text-[48px] font-bold leading-[52px] text-white md:pb-0 md:text-[64px] md:leading-[78px] lg:text-left`}
            >
              {title}
            </h2>
            <div className="hidden pb-[60px] text-center text-[16px] font-bold leading-[26px] tracking-[1px] text-gray-1 md:mb-2 md:block md:pb-[65px] lg:text-left">
              <StructuredText
                data={description}
                customRules={[
                  renderRule(isParagraph, ({ children, key }) => {
                    if (children[0].length === 0) return <br />
                    return (
                      <p
                        key={key}
                        className="text-xl leading-[25.92px] md:font-bold text-white"
                      >
                        {children}
                      </p>
                    )
                  }),
                ]}
              />
            </div>
          </div>
          {heroPhones && (
            <div className="absolute bottom-[-180px] right-[19%] mx-auto block max-w-[210px] md:top-[-115px] md:right-0 md:mx-0 md:mr-auto md:max-w-[535px] md:self-end">
              <GatsbyImage
                image={heroPhones.gatsbyImageData}
                alt="Hero Phones"
                className="mb-0 w-full"
              />
            </div>
          )}
        </LayoutContainer>
      </section>
      <LayoutContainer>
        <div className="mt-[145px] mb-[80px] text-[16px] leading-[26px] text-gray-1 md:hidden">
          <StructuredText
            data={description}
            customRules={[
              renderRule(isParagraph, ({ children, key }) => {
                // if (children[0].length === 0) return <br />
                return <p key={key}>{children}</p>
              }),
            ]}
          />
        </div>
      </LayoutContainer>
    </>
  )
}

export default FeaturesHero
