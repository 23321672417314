import { useStaticQuery, graphql } from "gatsby"

const useFeaturesPageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsFeaturePage {
        nodes {
          hero {
            title
            description {
              value
            }
            image {
              gatsbyImageData
              alt
            }
          }
          feature {
            id
            title
            description {
              value
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return data.allDatoCmsFeaturePage.nodes[0]
}

export default useFeaturesPageData
